import { shallowRef } from 'vue';

import { modalService } from '@exchange/libs/modals/src';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { capIsNativePlatform, capBrowser } from '@exchange/libs/utils/capacitor/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import RetailAmlModal from '../../../ui/src/libs/RetailAmlModal.vue';

const handleNativeGoTo = async ({ url, date }: { url: string; date: string | undefined | null }, handleCb: (date: string | undefined | null) => void) => {
  try {
    await capBrowser.addListener('browserFinished', () => {
      capBrowser.removeAllListeners();
      handleCb(date);
    });
    await capBrowser.open({ url });
  } catch (e) {
    logger.error(`Go to ${url} failed`, e);
    toastManagerInstance.addToast({
      content: SimpleToast,
      props: {
        variant: 'failed',
        title: 'fundamentals.toasts.failed',
        message: 'fundamentals.error.text',
      },
    });
  }
};

const handleMissingRetailAml = (amlDate: string | undefined | null) => {
  if (amlDate) {
    /** AML questionary was filled */
    return;
  }

  const mKey = modalService.show(
    shallowRef(RetailAmlModal),
    {},
    {
      'go-to-form': () => {
        const amlUrl = `${process.env.VUE_APP_ACCOUNTS_URL}${CONSTANTS.AML_PATHNAME}`;

        if (capIsNativePlatform()) {
          handleNativeGoTo(
            {
              url: amlUrl,
              date: amlDate,
            },
            handleMissingRetailAml,
          );
          modalService.hide(mKey);
        } else {
          window.location.href = amlUrl;
        }
      },
    },
    { canClose: false, variant: 'dark' },
  );
};

export default handleMissingRetailAml;
